<template>
    <div >
        ...logging in.
    </div>
</template>

<script>    
    export default {
        name: 'Login',          
        async mounted() {                      
            await this.$BlitzIt.auth.login();
        },             
    }
</script>